import React from 'react';
import styled from '@emotion/styled';
import device from '../helpers/breakpoints';
import { graphql, StaticQuery } from 'gatsby';

// Some classes to help with adding styling onScroll
import '../libs/css/general-nav-styles.css';
import NavLinks from './navLinks';

class Nav extends React.Component {
  constructor(props) {
    super();
    this.state = {
      root: props.Root,
      fixed: props.Fixed,
      shrinkMenu: !!props.Fixed,
      navbarOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll.bind(this), false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll.bind(this), false);
  }

  onScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 64;

    if (this.state.fixed || distanceY > shrinkOn) {
      this.setState({ shrinkMenu: true });
    } else {
      this.setState({ shrinkMenu: false });
    }
  }

  render() {
    const query = graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          publicURL
        }
        logometslagzin: file(relativePath: { eq: "logo_met_slagzin.png" }) {
          publicURL
        }
      }
    `;

    const LogoContainer = styled.div`
      display: block;
    `;

    const Logo = styled.img`
      width: 32px;
      height: 32px;
      display: block;
      opacity: 0;

      @media ${device.tablet} {
        display: none;
      }
    `;

    const LogoTablet = styled.img`
      width: 200px;
      display: none;
      opacity: 0;

      @media ${device.tablet} {
        display: block;
      }
    `;

    const LogoLink = styled.a`
      display: block;
      text-decoration: none;
    `;

    const LinkWrapper = styled.ul`
      display: flex;
      align-items: center;
      flex-direction: column;
      position: fixed;
      width: 100%;
      justify-content: flex-end;
      background-color: #fff;
      top: 3.8rem;
      left: ${(props) => (props.open ? '0' : '-100%')};
      padding: 1.4rem;
      box-shadow: ${(props) =>
        props.open ? '0 32px 32px 0 rgba(0, 0, 0, 0.1)' : 'none'};

      @media ${device.tablet} {
        background-color: transparent;
        margin-top: 0;
        justify-content: space-between;
        flex-direction: row;
        width: auto;
        max-width: initial;
        padding: 0;
        left: 0;
        position: sticky;
        transition: all 0.2s ease-in-out;
      }
    `;

    const LinkContainer = styled.nav`
      width: 100%;
      margin: 0;
      padding: 1.4rem 1rem;
      display: flex;
      justify-content: space-between;
      text-align: right;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 999;
      transition: all 0.2s ease-in-out;

      @media ${device.tablet} {
        text-align: left;
        padding: 1rem;
        padding-top: 0;
      }
    `;

    const Toggle = styled.div`
      display: flex;
      height: 100%;
      cursor: pointer;

      @media ${device.tablet} {
        display: none;
      }
    `;

    const Hamburger = styled.div`
      background-color: #fff;
      width: 30px;
      height: 3px;
      transition: all 0.3s linear;
      align-self: center;
      position: relative;
      transform: ${(props) => (props.open ? 'rotate(-45deg)' : 'inherit')};

      ::before,
      ::after {
        width: 30px;
        height: 3px;
        background-color: #fff;
        content: '';
        position: absolute;
        transition: all 0.3s linear;
        left: 0;
      }

      ::before {
        transform: ${(props) =>
          props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)'};
        top: -10px;
      }

      ::after {
        opacity: ${(props) => (props.open ? '0' : '1')};
        transform: ${(props) =>
          props.open ? 'rotate(90deg) ' : 'rotate(0deg)'};
        top: 10px;
      }
    `;

    return (
      <StaticQuery
        query={query}
        render={(data) => {
          return (
            <LinkContainer className={this.state.shrinkMenu ? 'smaller' : ''}>
              <LogoContainer>
                <LogoLink href="/">
                  <Logo className="nav-logo" src={data.logo.publicURL} />
                  <LogoTablet
                    className="nav-logo"
                    src={data.logometslagzin.publicURL}
                  />
                </LogoLink>
              </LogoContainer>
              <Toggle
                onClick={() =>
                  this.setState({ navbarOpen: !this.state.navbarOpen })
                }
              >
                <Hamburger
                  className="extended-small-hamburger"
                  open={this.state.navbarOpen}
                />
              </Toggle>
              <LinkWrapper open={this.state.navbarOpen}>
                <NavLinks root={this.state.root} />
              </LinkWrapper>
            </LinkContainer>
          );
        }}
      />
    );
  }
}

export default Nav;
