import React from 'react';
import { Helmet } from 'react-helmet';

import favicon32 from '../../images/favicon32.png';

export default ({ metadata }) => {
  if (!metadata || !metadata.description || !metadata.title) return null;

  const lang = 'nl';

  const title = metadata.name
    ? `${metadata.name} | ${metadata.title}`
    : metadata.title;
  const description = metadata.description;
  const image = metadata.image;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: `twitter:image`,
          content: image,
        },
        {
          name: `keywords`,
          content: `XPRTZ, .NET, .NET XPRT, .NET development`,
        },
      ]}
    >
      <link rel="shortcut icon" type="image/png" href={favicon32} />
    </Helmet>
  );
};
