import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { css } from '@emotion/core';
import { FaGithub, FaFacebook, FaInstagram, FaLinkedin, FaYoutubeSquare } from 'react-icons/fa';

const Footer = () => {
  const query = graphql`
    query {
      xprtz: file(relativePath: { eq: "symbol-xprtz.svg" }) {
        publicURL
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={data => (
        <div
          css={css`
            background-color: #212529;
            color: white;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;
            padding: 4rem 0 2rem;
            margin: 0 auto;
          `}
        >
          <div
            css={css`
              position: relative;
              margin: 0 auto;
              width: inherit;
            `}
          >
            <img
              src={data.xprtz.publicURL}
              alt="XPRTZ"
              width="80px"
              height="80px"
            />
          </div>
          <hr
            css={css`
              display: block;
              position: relative;
              margin: 0 auto;
              width: calc(100% - 4.2rem);
              margin-top: 1.5rem;
              margin-bottom: 1rem;
              border: 0;
              border-top: 1px solid rgba(255, 255, 255, 0.1);
            `}
          />
          <div
            css={css`
              margin: 0 auto;
              font-size: 1.4rem;
            `}
          >
            <a
              css={css`
                color: white;
              `}
              href="https://linkedin.com/company/xprtz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>{' '}
            &nbsp;
            <a
              css={css`
                color: white;
              `}
              href="https://github.com/xprtz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>{' '}
            &nbsp;
            <a
              css={css`
                color: white;
              `}
              href="https://instagram.com/workwithxprtz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>{' '}
            &nbsp;
            <a
              css={css`
                color: white;
              `}
              href="https://facebook.com/xprtz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>{' '}
            &nbsp;
            <a
              css={css`
                color: white;
              `}
              href="https://www.youtube.com/channel/UCFUV8Q5RgFMwN-XM_vkwT3g/featured"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutubeSquare />
            </a>
          </div>
        </div>
      )}
    ></StaticQuery>
  );
};

export default Footer;
