import React from 'react';
import styled from '@emotion/styled';
import {
  SmoothScrollAnchorLink,
  ExternalAnchorLink,
} from '../helpers/buttons/extended-anchor-link';
import device from '../helpers/breakpoints';

const CreateAnchorLink = (title, url, root) => {
  return root === undefined ? (
    <SmoothScrollAnchorLink className="extended-small-link" href={url}>
      {title}
    </SmoothScrollAnchorLink>
  ) : (
    <ExternalAnchorLink className="extended-small-link" to={root + url}>
      {title}
    </ExternalAnchorLink>
  );
};

const NavLinks = ({ root }) => {
  const LinkItem = styled.li`
    list-style: none;
    display: inline-block;
    margin-top: 0;
    margin-right: 0;
    line-height: 1;
    margin-bottom: 0.8rem;

    a {
      color: #000;
      font-size: 1.5rem;

      &::after {
        background: #000;
      }

      @media ${device.tablet} {
        font-size: 1rem;
        color: #fff;

        &::after {
          background: #fff;
        }
      }
    }

    @media ${device.tablet} {
      margin-right: 2rem;
      margin-bottom: 0;
      display: inline-block;

      &:last-of-type {
        margin-right: 0;
      }
    }
  `;

  return (
    <>
      <LinkItem>
        {CreateAnchorLink('Kernwaarden', '#kernwaarden-anchor', root)}
      </LinkItem>
      <LinkItem>
        {CreateAnchorLink('Techniek', '#techradar-anchor', root)}
      </LinkItem>
      <LinkItem>
        {CreateAnchorLink('Contact', '#contact-anchor', root)}
      </LinkItem>
      <LinkItem>
        {CreateAnchorLink('Werken met', 'werkenmet', root || '/')}
      </LinkItem>
      <LinkItem>
        {CreateAnchorLink('Werken bij', 'werkenbij', root || '/')}
      </LinkItem>

    </>
  );
};

export default NavLinks;
