import React from 'react';
import { Global, css } from '@emotion/core';
import device from '../helpers/breakpoints';
import SEO from '../components/seo';
import '../libs/css/custom-swiper-styles.css';

const Layout = ({ metadata, children }) => {
  return (
    <>
      <Global
        styles={css`
          * {
            box-sizing: border-box;
            margin: 0;
          }

          * + * {
            margin-top: 1rem;
          }

          html,
          body {
            margin: 0;
            color: #1a261f;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Arial, sans-serif;
            font-size: 1rem;
            line-height: 1.4;
            letter-spacing: 0.07rem;
            overflow-x: hidden;

            @media ${device.laptop} {
              font-size: 1.1rem;
              line-height: 1.5;
            }

            /* remove margin for main div where Gatsby mounts into */

            > div {
              margin-top: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #276b44;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #276b44;
            }

            li {
              margin-top: 0.25rem;
            }

            a {
              color: #276b44;
            }

            img + em {
              display: block;
              margin-top: 0;
              font-size: 18px;
            }

            code {
              background-color: #f5f5f5;
              padding: 1.75em;
              width: 100%;
              display: block;
              line-height: 1.75;
              overflow-x: auto;
            }

            table,
            th,
            td {
              border: 1px solid #ddd;
            }

            table {
              width: 100%;
              margin: 0 0 1.75em;
              table-layout: fixed;
              border-spacing: 0;
              border-collapse: separate;
              border-width: 1px 0 0 1px;
            }

            caption,
            th,
            td {
              font-weight: 400;
              text-align: left;
            }

            th {
              font-weight: 700;
              border-width: 0 1px 1px 0;
            }

            td {
              border-width: 0 1px 1px 0;
            }

            th,
            td {
              padding: 0.4375em;
            }
          }
        `}
      />
      <SEO metadata={metadata} />
      <main
        css={css`
          margin: 0 auto;
        `}
      >
        {children}
      </main>
    </>
  );
};

export default Layout;
